<script setup>
import { difference, isEmpty, keyBy, uniq } from 'lodash-es';
import { currencies } from '~/common/utils/constants.ts';

import { useCommonStore } from '~/common/stores/common.store';

const props = defineProps({
  store: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits(['import-success']);
const $services = inject('$services');

const common_store = useCommonStore();

const custom_field_format_map = {
  text: 'Text',
  number: 'Number',
  date: 'Date',
  phone_number: 'Number',
};

const legends = {
  error: {
    style: {
      backgroundColor: 'red', // Light red
      color: 'white',
    },
    label: 'Required/Invalid value',
    description: 'The data is either invalid/duplicate or can\'t be modified.',
  },
  warning: {
    style: {
      backgroundColor: '#ffff99', // Light yellow
      color: 'black',
    },
    label: 'Modified',
    description: 'The provided information is different from existing data',
  },
  disabled: {
    style: {
      backgroundColor: '#deddd9', // Light gray
    },
    label: 'Disabled',
    description: 'This field can\'t be edited',
  },
  new: {
    style: {
      backgroundColor: '#3399ff', // Light blue,
    },
    label: 'New Entry (Will be created)',
  },
};

const state = reactive({
  item_by_number: {},
  item_by_name: {},

  category_by_name: keyBy(props.store.item_types, 'name'),

  warehouse_by_name: keyBy(props.store.warehouses, 'name'),

  unit_by_name: keyBy(props.store.uoms, 'name'),
  unit_by_symbol: keyBy(props.store.uoms, 'symbol'),

  all_currencies: Object.keys(currencies),

  member_by_email: keyBy(common_store.users, 'email'),
});

const custom_fields = computed(() =>
  props.store.get_custom_fields({ attached_to: 'item' }, true),
);

function is_serial_number_enabled(data) {
  if (data)
    if (data.toLowerCase() === 'enabled')
      return true;
    else if (data.toLowerCase() === 'disabled')
      return false;
    else
      return 'invalid';

  return false;
}

function get_init_attributes(data = '') {
  return {
    is_disabled: false,
    is_valid: true,
    style: {
      backgroundColor: 'none',
    },
    translated_data: data,
    message: '',
  };
}

function get_disabled_attributes(data, message = '') {
  return {
    is_disabled: true,
    is_valid: true,
    style: legends.disabled.style,
    translated_data: data,
    message,
  };
}

function get_invalid_attributes(data, message = '') {
  return {
    is_disabled: false,
    is_valid: false,
    style: legends.error.style,
    translated_data: data,
    message,
  };
}

function get_create_attributes(data, message = '') {
  return {
    is_disabled: false,
    is_valid: true,
    style: legends.new.style,
    translated_data: data,
    message,
  };
}

function get_update_attributes(data, message = '') {
  return {
    is_disabled: false,
    is_valid: true,
    style: legends.warning.style,
    translated_data: data,
    message,
  };
}

function getPreHook(custom_field) {
  const preHookMap = {
    dropdown: (colData) => {
      return colData.map((data) => {
        let { is_valid, style, message } = get_init_attributes(data);
        if (data && !custom_field.config.includes(data))
          ({ is_valid, style, message } = get_invalid_attributes(data, 'is invalid'));
        return {
          data,
          properties: {
            is_valid,
            style,
            message,
          },
        };
      });
    },
    checkboxes: (colData) => {
      return colData.map((data) => {
        let { is_valid, style, message } = get_init_attributes(data);
        const selected_options = data.split(',').map(option => option.trim());
        for (const option of selected_options)
          if (option && !custom_field.config.includes(option)) {
            ({ is_valid, style, message } = get_invalid_attributes(data, 'is invalid'));
            break;
          }

        return {
          data,
          properties: {
            is_valid,
            style,
            message,
          },
        };
      });
    },
    member: (colData) => {
      return colData.map((data) => {
        let { is_valid, style, message } = get_init_attributes(data);
        if (data && !state.member_by_email[data])
          ({ is_valid, style, message } = get_invalid_attributes(data, 'is invalid'));
        return {
          data,
          properties: {
            is_valid,
            style,
            message,
          },
        };
      });
    },
    members: (colData) => {
      return colData.map((data) => {
        let { is_valid, style, message } = get_init_attributes(data);
        if (data) {
          const members_list = data?.split(',').map(member => member.trim());
          for (const member of members_list)
            if (member && !state.member_by_email[member]) {
              ({ is_valid, style, message } = get_invalid_attributes(data, 'is invalid'));
              break;
            }
        }
        return {
          data,
          properties: {
            is_valid,
            style,
            message,
          },
        };
      });
    },
    phone_number: (colData) => {
      return colData.map((data) => {
        let { is_valid, style, message } = get_init_attributes(data);
        // Check if starts with + and has overall length > 14 [+(countrycode length 2-4) (10 digits)]
        const reg = /^\+(?=.{13,}).*$/;
        if (data && !reg.test(data))
          ({ is_valid, style, message } = get_invalid_attributes(data, 'is invalid'));
        return {
          data,
          properties: {
            is_valid,
            style,
            message,
          },
        };
      });
    },
  };
  return preHookMap[custom_field.type];
}

function custom_fields_schema() {
  if (custom_fields.value.length)
    return custom_fields.value.map((custom_field) => {
      const column_config = {
        uid: custom_field.uid,
        field: custom_field?.item_meta?.label || custom_field?.name,
        label: custom_field?.item_meta?.label || custom_field?.name,
        data_type: custom_field.type === 'member' ? 'dropdown' : custom_field.type,
        data_format: custom_field_format_map[custom_field.type] || 'Text',
        description: custom_field.description,
        required: custom_field?.item_meta?.properties?.mandatory || custom_field.mandatory,
        ...(custom_field.type === 'dropdown' && { source: custom_field.config }),
        ...(custom_field.type === 'member' && { source: common_store.users.map(user => user.email) }),
      };

      const preHook = getPreHook(custom_field);
      if (preHook)
        column_config.preHook = preHook;
      return column_config;
    });

  return [];
}

function get_csv_custom_fields_data(item) {
  const custom_fields_data = [];
  custom_fields_schema().forEach((custom_field) => {
    if (item[custom_field.field]) {
      // Set value variable to the value from the importer by default
      let value = item[custom_field.field];
      if (custom_field.type === 'member') {
        // If custom field is of the type member then update the value with the uid
        const member_uid = state.member_by_email[item[custom_field.field]]
          .uid;
        value = member_uid;
      }
      else if (custom_field.type === 'members') {
        // If custom field is of the type members then update the value with the uid list
        const member_emails_array = value.split(',').map(member_email => member_email.trim());
        const uid_list = [];
        member_emails_array.forEach(email =>
          uid_list.push(state.member_by_email[email].uid),
        );
        value = uid_list;
      }
      else if (custom_field.type === 'checkboxes') {
        // If custom field is of the type checkboxes then update the value as an array
        const selected_options = value
          .split(',')
          .map(option => option.trim());
        value = selected_options;
      }
      custom_fields_data.push({
        uid: custom_field.uid,
        value,
      });
    }
  });
  return custom_fields_data;
}

const columns = ref([
  {
    field: 'number',
    label: 'Item code',
    data_type: 'text',
    data_format: 'Text',
    required: true,
    unique: true,
    description: 'A unique identifier for tracking and referencing the item.',
    example: 'MOD : 61415',
    preHook: async (colData) => {
      return colData.map((data) => {
        let { style, message } = get_init_attributes(data);
        if (state.item_by_number[data])
          ({ style, message } = get_update_attributes(data, 'Item already exists with this number, Details will be updated'));

        return {
          data,
          properties: {
            is_valid: true,
            style,
            message,
          },
        };
      });
    },
  },
  {
    field: 'name',
    label: 'Item name',
    data_type: 'text',
    data_format: 'Text',
    required: true,
    description: 'Name given to the item for identification.',
    example: 'Smart module',
    preHook: async (colData, formattedData) => {
      return colData.map((data, idx) => {
        let { is_valid, style, message } = get_init_attributes(data);
        const item = state.item_by_number[formattedData[idx].number];
        const item_by_name = state.item_by_name[data];
        if (item) {
          if (item_by_name && item.uid !== item_by_name.uid)
            ({ is_valid, style, message } = get_invalid_attributes(data, 'already exists'));
          else if (item.name !== data)
            ({ style, message } = get_update_attributes(data, 'Item name will be updated'));
        }
        else if (item_by_name) {
          ({ is_valid, style, message } = get_invalid_attributes(data, 'already exists'));
        }
        return {
          data,
          properties: {
            is_valid,
            message,
            style,
          },
        };
      });
    },
  },
  {
    field: 'category',
    label: 'Type',
    source: props.store.item_types.map(category => category.name),
    data_type: 'dropdown',
    data_format: 'Text',
    required: false,
    description: 'Type of the item',
    example: 'Structures',
    preHook: async (colData, formattedData) => {
      return colData.map((data, idx) => {
        const item = state.item_by_number[formattedData[idx].number];
        let { style, message } = get_init_attributes(data);
        if (data)
          if (item) {
            const category = props.store.item_types_map[item.category];
            if (category && data !== category.name)
              ({ style, message } = get_update_attributes(data, 'Category will be updated'));
          }
          else if (!state.category_by_name[data]) {
            ({ style, message } = get_create_attributes(data, 'New category will be created'));
          }

        return {
          data,
          properties: {
            is_valid: true,
            style,
            message,
          },
        };
      });
    },
    postHook: async (colData) => {
      const category_names = props.store.item_types.map(category => category.name);
      const new_categories = uniq(difference(colData, category_names))
        .filter(category => category)
        .map((category) => {
          return { name: category };
        });

      if (new_categories.length)
        await props.store.create_item_type({ payload: new_categories });

      return colData.map((data) => {
        return {
          data,
          properties: {
            is_valid: true,
            style: {
              backgroundColor: 'none',
            },
            message: '',
          },
        };
      });
    },
  },
  {
    field: 'is_serial_number',
    label: 'Serial number',
    data_type: 'text',
    data_format: 'Text',
    required: false,
    description: 'Enable unique code for identification and tracking of an item.',
    example: 'Enabled',
    preHook: (colData, formattedData) => {
      return colData.map((data, idx) => {
        // Will be set to empty and disabled if item already exists
        const item = state.item_by_number[formattedData[idx].number];
        let { translated_data, is_valid, is_disabled, style, message } = get_init_attributes(data);
        if (item) {
          ({ translated_data, is_valid, is_disabled, style, message } = get_disabled_attributes('', 'Cannot alter serial number tracking for exisiting item'));
        }
        else if (data) {
          const serial_number_tracking_data = is_serial_number_enabled(data);
          if (serial_number_tracking_data === 'invalid')
            ({ translated_data, is_valid, is_disabled, style, message } = get_invalid_attributes(data, 'is invalid, Can be enabled/disabled'));
        }
        return {
          data: translated_data,
          properties: {
            is_valid,
            is_disabled,
            style,
            message,
          },
        };
      });
    },
  },
  {
    field: 'cost',
    label: 'Cost Per Item',
    data_type: 'number',
    data_format: 'Number',
    required: false,
    description: 'Cost of one unit of an Item',
    example: '50',
    preHook: (colData, formattedData) => {
      return colData.map((data, idx) => {
        // Will be set to 0 if no data is present
        const item = state.item_by_number[formattedData[idx].number];
        let { translated_data, style, message } = get_init_attributes(0);
        if (data) {
          translated_data = data;
          if (item && Number(data) !== Number(item.cost))
            ({ style, message } = get_update_attributes(data, 'Cost will be updated'));
        }

        return {
          data: translated_data,
          properties: {
            is_valid: true,
            style,
            message,
          },
        };
      });
    },
  },
  {
    field: 'cost_currency',
    label: 'Currency',
    data_type: 'dropdown',
    data_format: 'Text',
    source: state.all_currencies,
    required: false,
    description: 'Cost Currency',
    example: 'USD',
    preHook: (colData, formattedData) => {
      return colData.map((data, idx) => {
        const item = state.item_by_number[formattedData[idx].number];
        // Stays disabled if cost === 0
        let { translated_data, is_valid, is_disabled, style, message } = get_disabled_attributes('', 'Cost is 0');
        const cost = formattedData[idx].cost;
        if (Number(cost)) {
          ({ translated_data, is_valid, is_disabled, style, message } = get_init_attributes(data));
          if (data && state.all_currencies.includes(data)) {
            if (item && item.cost_currency !== data)
              ({ is_valid, is_disabled, style, message } = get_update_attributes(data, 'Currency will be updated'));
          }
          else {
            ({ translated_data, is_valid, is_disabled, style, message } = get_invalid_attributes(data, 'is invalid'));
          }
        }
        return {
          data: translated_data,
          properties: {
            is_valid,
            is_disabled,
            style,
            message,
          },
        };
      });
    },
  },
  {
    field: 'uom',
    label: 'Units',
    data_type: 'alphabetic_text',
    data_format: 'Text',
    required: true,
    description: 'Units of measurement',
    example: 'pcs',
    preHook: async (colData, formattedData) => {
      return colData.map((data, idx) => {
        const item = state.item_by_number[formattedData[idx].number];
        let { style, message } = get_init_attributes(data);
        if (item) {
          const uom = props.store.uom_map[item.uom];
          if (uom && (data !== uom.name || data !== uom.symbol))
            ({ style, message } = get_update_attributes(data, 'UoM will be updated'));
        }
        else if (!(state.unit_by_name[data] || state.unit_by_symbol[data])) {
          ({ style, message } = get_create_attributes(data, 'UoM will be created'));
        }
        return {
          data,
          properties: {
            is_valid: true,
            style,
            message,
          },
        };
      });
    },
    postHook: async (colData) => {
      const new_units = uniq(
        colData.filter(data => !(state.unit_by_name[data] || state.unit_by_symbol[data])))
        .map((uom) => {
          return { name: uom, symbol: uom };
        });

      if (new_units.length)
        await props.store.create_uom({ payload: new_units });

      return colData.map((data) => {
        return {
          data,
          properties: {
            is_valid: true,
            style: {
              backgroundColor: 'none',
            },
            message: '',
          },
        };
      });
    },
  },
  {
    field: 'description',
    label: 'Description',
    data_type: 'text',
    data_format: 'Text',
    required: false,
    description: 'Item details',
    example: 'M12 150mm',
    preHook: async (colData, formattedData) => {
      return colData.map((data, idx) => {
        const item = state.item_by_number[formattedData[idx].number];
        let { style, message } = get_init_attributes(data);
        if (item)
          if (data !== item.description)
            ({ style, message } = get_update_attributes(data, 'Description will be updated'));

        return {
          data,
          properties: {
            is_valid: true,
            style,
            message,
          },
        };
      });
    },
  },
]);

const methods = {
  init: async () => {
    const { data } = await $services.inventory_items.getAll({
      query: {
        limit: Number.MAX_SAFE_INTEGER,
      },
    });
    state.item_by_number = keyBy(data.items, 'number');
    state.item_by_name = keyBy(data.items, 'name');
  },
  callback: async (data) => {
    const items_to_be_created = [];
    const items_to_be_updated = [];
    data.forEach((item) => {
      // Get category uid, uom uid, warehouse uid
      const category_obj = props.store.item_types.find(category => category.name === item.category);
      const uom_obj = props.store.uoms.find(unit => unit.name === item.uom || unit.symbol === item.uom);
      const custom_fields = get_csv_custom_fields_data(item);
      const existing_item = state.item_by_number[item.number];
      if (existing_item) {
        // Update Item
        items_to_be_updated.push({
          uid: existing_item.uid,
          name: item.name,
          uom: uom_obj.uid,
          ...(item?.description && { description: item.description }),
          ...(category_obj && { category: category_obj.uid }),
          ...(item?.cost && { cost: item.cost }),
          ...(item?.cost_currency && { cost_currency: item.cost_currency }),
          custom_fields,
        });
      }
      else {
        // Create Item
        // Send stock info only if warehouse info is present
        let opening_stocks = [];
        let warehouse_obj = {};
        if (item.warehouse && Number.parseInt(item.quantity, 10) > 0)
          warehouse_obj = props.store.warehouses.find(warehouse => warehouse.name === item.warehouse);

        if (!isEmpty(warehouse_obj))
          opening_stocks = [
            {
              warehouse: warehouse_obj.uid,
              quantity: Number.parseInt(item.quantity, 10),
            },
          ];

        items_to_be_created.push({
          number: item.number,
          name: item.name,
          uom: uom_obj.uid,
          ...(item?.description && { description: item.description }),
          ...(category_obj && { category: category_obj.uid }),
          ...(item?.cost && { cost: item.cost }),
          ...(item?.cost_currency && { cost_currency: item.cost_currency }),
          opening_stocks,
          is_serial_number: is_serial_number_enabled(item.is_serial_number),
          custom_fields,
        });
      }
    });
    const promiseCollection = [];
    if (items_to_be_created.length)
      promiseCollection.push(
        props.store.create_inventory_items({
          items: items_to_be_created,
        }),
      );

    if (items_to_be_updated.length)
      promiseCollection.push(
        props.store.update_inventory_items({
          items: items_to_be_updated,
        }),
      );

    await Promise.all(promiseCollection);
  },
};

const schema = reactive({
  legends,
  columns: [...columns.value, ...custom_fields_schema()],
  methods,
});
</script>

<template>
  <csv-importer
    :schema="schema"
    @import-success="emit('import-success')"
  />
</template>
