<script setup>
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useThermStore } from '~/therm/store/therm.store';
import ThermReportGraph from '~/therm/components/therm-report-graph.vue';

const { $t } = useCommonImports();
const therm_store = useThermStore('therm_dashboard');

const defect_status_graph_details = computed(() => {
  return {
    type: 'pie2d',
    title: $t('Defects by status'),
    id: 'defect_status_container',
  };
});
const defect_status_graph_data = computed(() => {
  const status_data_map = therm_store.status_configs.reduce((acc, curr) => {
    acc[curr.uid] = 0;
    return acc;
  }, {});
  therm_store.report_defects.forEach((feature) => {
    const status = feature.status;
    if (status)
      status_data_map[status]++;
  });
  const data = [];
  for (const status_uid in status_data_map) {
    const status = therm_store.status_map[status_uid];
    data.push({
      label: status.name,
      value: status_data_map[status_uid],
      color: status.color,
    });
  }
  return { data, description: false, type: 'status' };
});
</script>

<template>
  <div class="border rounded-xl h-[260px] p-4">
    <ThermReportGraph
      :data="defect_status_graph_data"
      :graph_details="defect_status_graph_details"
      :has_borders="false"
      height="200"
    />
  </div>
</template>
