<script setup>
import { cloneDeep, orderBy } from 'lodash-es';
import { computed } from 'vue';
import { trainCase } from '~/common/utils/common.utils';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useCustomViewStore } from '~/common/stores/custom-view.store.js';
import { useThermStore } from '~/therm/store/therm.store';
import { useThermHelperComposable } from '~/therm/composables/helper-composable.js';
import SecondaryPageHeader from '~/common/components/organisms/hawk-page-secondary-header.vue';
import ThermScanDates from '~/therm/components/therm-scan-dates.vue';
import ThermDashboardDisplayFilters from '~/therm/components/dashboard/therm-dashboard-display-filters.vue';
import DefectCountGraph from '~/therm/components/dashboard/defect-count-graph.vue';
import DefectBySeverityGraph from '~/therm/components/dashboard/defect-by-severity-graph.vue';
import DefectByStatus from '~/therm/components/dashboard/defect-by-status-graph.vue';
import DefectTemperatureGraph from '~/therm/components/dashboard/defect-temperature-graph.vue';
import DefectResolutionGraph from '~/therm/components/dashboard/defect-resolution-graph.vue';
import DefectClassificationTable from '~/therm/components/dashboard/defect-classification-table.vue';

const {
  $date_relative,
  $date,
  $services,
  $t,
  $toast,
  $track_event,
  auth_store,
  common_store,
  route,
  router,
} = useCommonImports();

const { calculateWatts } = useThermHelperComposable();

const custom_view_store = useCustomViewStore('therm_view_store');
const therm_store = useThermStore('therm_dashboard');

const state = reactive({
  summary: {},
  defects: [],
  is_loading: true,
  data_loading: false,
  filters_map: {},
});

const show_summary = computed(() => !state.data_loading || state.data_loading === 'filtering');
const view_configuration = computed(() => ({
  name: auth_store.logged_in_user_id,
  service: 'therm',
  feature: 'reports',
  resource_id: route.params.id,
  resource_type: 'defects',
  asset: route.params.asset_id,
  private: true,
}));
const active_view = computed(() => custom_view_store?.views?.find((view) => {
  const { service, resource_type, feature, resource_id, name } = view_configuration.value;
  return view.service === service && view.resource_type === resource_type && view.feature === feature && view.resource_id === resource_id && view.name === name;
}));

function getPercentage(value, total) {
  if (total === 0)
    return 0;

  return Number.parseFloat((value / total) * 100).toFixed(2);
}

async function getReports(filters = null, disable_save_view = false, change_group = false) {
  state.data_loading = (filters && !change_group) ? 'filtering' : true;
  state.filters_map = filters || {};
  if (!therm_store.active_group) {
    const first_group = orderBy(Object.values(therm_store.container?.groups),
      group => group.properties.date || group.name,
      ['desc'],
    )?.[0];
    therm_store.set_active_group({ group: first_group });
  }
  try {
    const response = await $services.therm.post({
      attribute: 'metrics/reports',
      body: {
        view: therm_store.container.uid,
        group: therm_store.active_group.uid,
        type: 'group_summary_and_filtered_defects',
        filters: state.filters_map,
      },
    });
    state.summary = response.data.summary;
    therm_store.report_defects = response.data.defects;
    if (!disable_save_view)
      updateView();
  }
  catch (error) {
    logger.error(`Fetching data failed ${error}`);
    state.summary = {};
    therm_store.report_defects = [];
  }
  state.data_loading = false;
}

async function updateView() {
  const payload = {
    ...view_configuration.value,
    data: {
      group: therm_store.active_group.uid,
      filters: state.filters_map,
    },
  };
  if (active_view.value && Object.keys(active_view.value).length)
    await custom_view_store?.update_view(payload, active_view.value.uid);
  else await custom_view_store?.create_view(payload);
}

async function init() {
  try {
    const store = useThermStore();
    therm_store.container = cloneDeep(store.container);
    therm_store.ftg = cloneDeep(store.ftg);
    therm_store.status_configs = cloneDeep(store.status_configs);
    await custom_view_store.set_custom_views({ query: view_configuration.value });
    if (active_view.value?.data?.group)
      therm_store.set_active_group({ group: store.container.groups[active_view.value?.data?.group] });
    await getReports(active_view.value?.data?.filters, true);
  }
  catch (error) {
    logger.error(`Fetching data failed ${error}`);
  }
}

async function handleChangeGroup() {
  if (state.filters_map.blocks)
    delete state.filters_map.blocks;
  await getReports(state.filters_map, false, true);
}

watch(() => useThermStore().is_data_loaded, async (val) => {
  state.is_loading = true;
  if (val) {
    await init();
    state.is_loading = false;
  }
}, { immediate: true });
</script>

<template>
  <div class="border-t py-4 h-full scrollbar">
    <HawkLoader v-if="state.is_loading" />
    <div v-else>
      <div class="px-4">
        <SecondaryPageHeader>
          <template #left>
            <div class="flex flex-col gap-1">
              <div class="text-md font-semibold text-gray-900">
                {{ $t('Defects summary') }}
              </div>
              <div class="text-xs text-gray-600">
                {{ $t('High-level overview of the impact and resolution status of defects across the site') }}
              </div>
            </div>
          </template>
          <template #right>
            <ThermScanDates
              is_dashboard_view
              therm_store_name="therm_dashboard"
              :handle_toggle_group="handleChangeGroup"
            />
          </template>
        </SecondaryPageHeader>
        <div v-if="show_summary" class="grid grid-cols-2 flex-wrap gap-4 min-h-32 my-4">
          <div class="grid grid-cols-3 gap-4 border rounded-lg px-4 py-2">
            <div class="grid grid-rows-3 items-center">
              <div class="text-sm font-semibold">
                {{ $t('Affected Capacity') }}
              </div>
              <div class="text-[24px] font-bold text-error-600">
                {{ calculateWatts(state.summary.capacity.affected.total) }}
              </div>
              <div class="text-sm font-medium text-success-600 flex items-center gap-x-0.5">
                <IconHawkCheckCircleGreen class="w-4 h-4" />
                {{ calculateWatts(state.summary.capacity.affected.recovered) }}
                {{ $t('recovered') }}
              </div>
            </div>
            <div class="grid grid-rows-3 items-center">
              <div class="text-sm font-semibold">
                {{ $t('Total Capacity') }}
              </div>
              <div class="text-[24px] font-bold text-gray-900">
                {{ calculateWatts(state.summary.capacity.total) }}
              </div>
            </div>
            <div class="grid grid-rows-3 items-center">
              <div class="text-sm font-semibold">
                {{ $t('Affected modules') }}
              </div>
              <div class="text-[24px] font-bold text-gray-900">
                {{ state.summary.module.affected }}
              </div>
              <div class="text-sm font-medium text-gray-600 flex items-center gap-x-0.5">
                <IconHawkInfoCircleGray class="w-4 h-4" />
                {{ getPercentage(state.summary.module.affected, state.summary.module.total) }}
                {{ $t('of total') }}
              </div>
            </div>
          </div>
          <div class="grid grid-cols-3 gap-4 border rounded-lg px-4 py-2">
            <div class="grid grid-rows-3 items-center">
              <div class="text-sm font-semibold">
                {{ $t('Most common defect') }}
              </div>
              <div class="text-[24px] font-bold text-error-600">
                {{ trainCase(state.summary.defect.most_common.type || '') || 'N/A' }}
              </div>
              <div class="text-sm font-medium text-gray-600 flex items-center gap-x-0.5">
                <IconHawkInfoCircleGray class="w-4 h-4" />
                {{ getPercentage(state.summary.defect.most_common.total, state.summary.defect.total) }}
                {{ $t('of total') }}
              </div>
            </div>
            <div class="grid grid-rows-3 items-center">
              <div class="text-sm font-semibold">
                {{ $t('Most affected block') }}
              </div>
              <div class="text-[24px] font-bold text-gray-900">
                {{ state.summary.block.most_affected.name || 'N/A' }}
              </div>
              <div class="text-sm font-medium text-gray-600 flex items-center gap-x-0.5">
                {{ getPercentage(state.summary.block.most_affected.defect_count.resolved, state.summary.block.most_affected.defect_count.total) }}%
                {{ $t('resolved') }}
              </div>
            </div>
            <div class="grid grid-rows-3 items-center">
              <div class="text-sm font-semibold">
                {{ $t('Resolved defects') }}
              </div>
              <div class="text-[24px] font-bold text-gray-900">
                {{ state.summary.defect.resolved }}
              </div>
              <div class="text-sm font-medium text-success-600 flex items-center gap-x-0.5">
                <IconHawkCheckCircleGreen class="w-4 h-4" />
                {{ getPercentage(state.summary.defect.resolved, state.summary.defect.total) }}%
                {{ $t('of total') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="show_summary" class="h-2 bg-gray-200 mt-2" />
      <div v-show="!state.data_loading" class="px-4 my-4">
        <ThermDashboardDisplayFilters
          :therm_store="therm_store"
          :default_filters_map="state.filters_map"
          @applyFilter="getReports($event)"
        />
        <div class="flex mt-4 gap-4">
          <div class="flex-1">
            <DefectCountGraph />
          </div>
          <div class="flex-none w-[400px]">
            <div class="flex flex-col gap-4">
              <DefectBySeverityGraph />
              <DefectByStatus />
            </div>
          </div>
        </div>
        <DefectClassificationTable :key="state.data_loading" />
        <DefectResolutionGraph />
        <DefectTemperatureGraph />
      </div>
      <HawkLoader v-if="state.data_loading" />
    </div>
  </div>
</template>
