<script setup>
import { isPlainObject, omit } from 'lodash-es';
import { storeToRefs } from 'pinia';

import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import { useDashboardFormsStore } from '~/dashboard/store/dashboard-forms.store.js';
import Widgets from '~/dashboard/components/widgets/widget.vue';

const props = defineProps({
  is_editing: {
    type: Boolean,
    default: false,
  },
  can_modify_resource: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['openInventoryForm']);
const dashboard_store = useDashboardStore();
const dashboard_forms_store = useDashboardFormsStore();

const {
  unparse_configuration,
} = dashboard_store;

const {
  forms_v2_filters,
} = storeToRefs(dashboard_forms_store);

const layout = computed(() => {
  if (isPlainObject(dashboard_store.current_dashboard?.report_data)) {
    const reports = omit(dashboard_store.current_dashboard?.report_data, 'asset_metadata');
    return Object.values(reports).filter(r => r.type) || [];
  }
  else {
    return [];
  }
});

function setWidth(widget) {
  if (dashboard_store.is_mobile_view) {
    return 12;
  }
  else {
    if (['spi', 'cpi', 'activities_metrics', 'number_chart', 'metric', 'progress_card'].includes(widget?.type || widget?.data?.type))
      widget.w = 3;
    else if ([1, 2, 3].includes(widget.w))
      widget.w = 4 * widget.w;
    return widget.w;
  }
}
function setHeight(widget) {
  if (['spi', 'cpi', 'activities_metrics', 'number_chart', 'metric', 'progress_card'].includes(widget?.type || widget?.data?.type))
    return 5;

  else return widget.h;
}
function minW(chart) {
  if (['map-view', 'single_form_view'].includes(chart))
    return 12;
  else if (['container_views', 'map_view', 'tasks_list', 'forms_list', 'activity_history'].includes(chart))
    return 6;
  else if (['pivot table'].includes(chart))
    return 8;
  else if (!canResize(chart))
    return 3;
  return 4;
}

function minH(chart) {
  if (!canResize(chart))
    return 5;
  if (
    [
      'activities_work_combined_table', 'activities_work_table', 'activities_table', 'workprogress_terra_chart', 'form_responses', 'rich_text'].includes(chart)
  )
    return 5;
  if (chart === 'single_form_view')
    return 25;

  return 15;
}

function canResize(chart) {
  return !['spi', 'cpi', 'activities_metrics', 'number_chart', 'activity_history', 'metric', 'progress_card', 'transmittal_detail_view'].includes(chart);
}

const forceUpdate = ref(0);
watch(() => dashboard_store.current_dashboard.uid, (new_val, old_val) => {
  if (new_val)
    forceUpdate.value++;
});
watch(forms_v2_filters, (nv, ov) => {
  forceUpdate.value++;
}, { deep: true });
</script>

<template>
  <div v-show="layout && layout.length" id="hawk-dashboard" :key="forceUpdate" class="bg-[#F9FAFB] h-full w-full" :class="{ 'pixel-background': props.is_editing }">
    <GridLayout
      :layout="layout"
      :col-num="12"
      :row-height="15"
      :is-draggable="props.is_editing"
      :is-resizable="props.is_editing"
      :cols="{ lg: 3, md: 3, sm: 1, xs: 1, xxs: 1 }"
    >
      <template #default="{ gridItemProps }" >
        <Widgets
        v-bind="gridItemProps"
        v-for="(item) in layout"
          :id="item.uid"
          :key="item.uid"
          :config="unparse_configuration(item)"
          :x="item.x"
          :y="item.y"
          :w="setWidth(item)"
          :h="setHeight(item)"
          :min-w="minW(item.type || item.data.type)"
          :min-h="minH(item.type || item.data.type)"
          :is_editing="props.is_editing"
          :auto-size="false"
          :is-resizable="props.is_editing ? canResize(item.type || item.data.type) : false"
          :can_modify_resource="props.can_modify_resource"
          drag-ignore-from=".is-map"
        />
      </template>
    </GridLayout>
  </div>
  <div v-if="!layout || !layout.length" class="w-full flex justify-center items-center text-sm font-semiBold">
    <HawkIllustrations v-if="dashboard_store.scope === 'inventory'" for="inventory-report" type="get-started" @onCtaClick="emit('openInventoryForm')" />
    <HawkIllustrations v-else for="dashboard-widgets" type="no-data" />
  </div>
</template>

<style lang="scss" scoped>
.pixel-background {
  background-size: 22.18px 17.7px;
  background-image: conic-gradient(transparent 0deg, transparent 90deg, white 90deg, white 180deg, transparent 180deg, transparent 270deg, white 270deg);
  width: 100%;
  background-clip: padding-box;
  min-height: 25rem;
  padding-bottom: 50px;
}
</style>
