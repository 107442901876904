<script setup>
import { computed, inject, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { cloneDeep, isEmpty, keyBy } from 'lodash-es';
import { storeToRefs } from 'pinia';

// other
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { useModal } from 'vue-final-modal';
import IconHawkSharepoint from '~icons/integrations/sharepoint';
import { getFileExtension } from '~/common/utils/common.utils.js';
import { TRANSMITTAL_STATUS } from '~/dms/constants';

// composables
import { useDocumentActions } from '~/dms/composables/document-actions.composable';
import { useDocumentPermissions } from '~/dms/composables/document-permissions.composable';
import { useDocumentBulkActions } from '~/dms/composables/document-bulk-actions.composable';
import { useDocumentCrud } from '~/dms/composables/document-crud.composable';

// stores
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store';
import { useDocumentStore } from '~/dms/store/document.store';
import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';
import { useExportDocumentsMetadata } from '~/dms/composables/document-metadata-export.composable';

// components
import DocumentBreadcrumbs from '~/dms/components/atoms/document-breadcrumbs.vue';
import DocumentContextMenu from '~/dms/components/documents/table/document-context-menu.vue';
import DocumentVersionForm from '~/dms/components/documents/forms/document-version-form.vue';
import DocumentVersions from '~/dms/components/documents/versions/document-versions.vue';

import HawkActivities from '~/common/components/organisms/hawk-activities/hawk-activities.vue';
import HawkShare from '~/common/components/organisms/hawk-share.vue';
import DocumentTasks from '~/dms/components/atoms/document-tasks.vue';
import DocumentForms from '~/dms/components/atoms/document-forms.vue';
import HawkConfirmationPopup from '~/common/components/organisms/hawk-confirmation-popup.vue';
import DocumentSyncPopup from '~/dms/components/atoms/document-sync-popup.vue';

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  options: {
    type: Object,
    default: () => ({}),
  },
  source: {
    type: String,
    default: 'files',
  },
  active_tab: {
    type: String,
    default: 'files',
  },
  is_loading: {
    type: Boolean,
    required: true,
  },
});
const emit = defineEmits(['attachmentClick', 'close']);

const $t = inject('$t');
const $toast = inject('$toast');

const document_actions = useDocumentActions({ source: props.source });
const document_bulk_actions = useDocumentBulkActions();
const document_permissions = useDocumentPermissions();
const document_crud = useDocumentCrud();
const { exportDocumentsMetaData, is_metadata_exporting, startExporting, cancelExportingMetadata } = useExportDocumentsMetadata();
const route = useRoute();
const router = useRouter();

const document_store = useDocumentStore();
const dms_settings_store = useDMSSettingsStore();
const common_store = useCommonStore();
const auth_store = useAuthStore();

const transmittal_status_class_map = {
  orange: 'bg-orange-50 text-orange-700',
  lightgreen: 'bg-emerald-50 text-emerald-700',
};

const { is_internal } = storeToRefs(document_store);
const active_details_tab = ref('details');
const target_element = ref({});
const hawk_activities = ref(null);

const isFolder = computed(() => document_store.active_item_details.type === 'folder');
const file_details = computed(() => document_store.active_item_details);
const integrations = computed(() => file_details.value?.integrations?.filter(integration => integration.active));

const shouldShow = computed(() => document_store.show_details);
const current_version_placeholder = computed(() => props.item?.versions?.find(version => version.uid === props.item.defaultversion)?.thumbnail_small);
const custom_fields = computed(() => dms_settings_store.custom_fields.filter(field => field.active));
const document_statuses = computed(() => dms_settings_store.document_status.filter(document_status => document_status.active));
const field_values_map = computed(() => keyBy(file_details.value?.field_values, 'field'));

async function getTargetElement() {
  let assetId = route.params.asset_id;
  if (document_store.active_folder_meta.type === 'asset')
    assetId = document_store.active_folder_meta.uid;
  const response = await common_store.get_target_element(assetId, target_element);
  target_element.value = response;
  updateActivities();
}

onMounted(async () => {
  await getTargetElement();
});
onUpdated(async () => {
  await getTargetElement();
});

function updateActivities() {
  hawk_activities?.value?.getData({ sort: '-createdAt' });
}
const version_modal = useModal({
  component: DocumentVersionForm,
});

function openVersionModal() {
  version_modal.patchOptions({
    attrs: {
      onClose() {
        version_modal.close();
      },
      on_submit: data => document_crud.updateVersion(data, props.item, true),
      item: props.item,
    },
  });
  version_modal.open();
}

const remove_integration_modal = useModal({
  component: HawkConfirmationPopup,
});

function openRemoveIntegrationModal({ id }) {
  remove_integration_modal.patchOptions({
    attrs: {
      header: $t('Remove integration'),
      content: $t('You are about to remove the integration. Existing documents in SharePoint will remain unaffected, though future updates will not be synced. Are you sure?'),
      onClose() {
        remove_integration_modal.close();
      },
      confirm: async () => {
        try {
          await document_store.remove_integration(
            {
              attribute: `${props.item.uid}/integrations/${id}`,
              document_uid: props.item.uid,
              integration_id: id,
            });
        }
        catch (e) {
          logger.error(e);
        }
        remove_integration_modal.close();
      },
    },
  });
  remove_integration_modal.open();
}

const share_modal = useModal({
  component: HawkShare,
  slots: {
    global_access: '',
  },
});

async function onShare(data) {
  const is_private = data.global_access_level === 'no_access';
  const { type, uid } = props.item || {};
  try {
    await document_store.crud_documents({
      request: {
        body: {
          [`${type}s`]: {
            update: [
              {
                uid,
                ...(type === 'folder' ? { private: is_private } : {}),
                members: [
                  ...data.users, // members
                  ...data.teams, // teams
                ],
              },
            ],
          },
        },
      },
    });
    $toast({
      title: $t('File/folder shared successfully'),
      text: $t(`The ${type} have been shared successfully`),
      type: 'success',
      position: 'bottom-right',
    });
  }
  catch (e) {
    logger.error('SHARE: ', e);
    $toast({
      title: $t('Something went wrong'),
      text: $t('Please try again later'),
      type: 'error',
      position: 'bottom-right',
    });
  }
}

function openShareModal() {
  const teams = [];
  const users = [];
  const copy_items = cloneDeep(props.item);
  copy_items.members.forEach((member) => {
    if (common_store.teams_map[member.uid])
      teams.push(member);
    else
      users.push(member);
  });

  share_modal.patchOptions({
    slots: {
      global_access: document_bulk_actions.getOrgAssetLabel(copy_items),
    },
    attrs: {
      onClose() {
        share_modal.close();
      },
      is_modal: true,
      teams,
      is_global_access_visible: copy_items?.type === 'folder' && !copy_items?.parent && copy_items?.actions?.can_mark_folder_public,
      global_access_level: copy_items?.private ? 'no_access' : 'read',
      members: users,
      global_access_levels: [
        { name: 'no_access', label: $t('No access'), description: $t('Can not access unless shared with them') },
        { name: 'read', label: $t('Can view'), description: $t('Can view the items of the folder and subfolders') },
      ],
      access_levels: [
        { name: 'read', label: $t('Can view'), description: $t('Can view') },
        { name: 'write', label: $t('Can edit'), description: $t('Can view and edit') },
      ],
      get_share_data(data) {
        onShare(data);
      },
    },
  });
  share_modal.open();
}

function openTransferOwnershipModal() {
  document_actions.openTransferOwnership({ item: props.item });
}

async function itemDownload() {
  const data = { item: props.item };
  if (route.meta.page === 'archive')
    data.query = { archive: true };
  document_crud.itemDownload(data);
}

function getSupportedAttachment(file) {
  const extension = getFileExtension(file.file_name);
  if (!window.Core)
    return true;

  return window.Core.SupportedFileFormats.CLIENT.includes(extension.toLowerCase());
}

function onSliderClose() {
  document_store.set_show_details(false);
  router.push({ query: { folder: route.query.folder } });
  emit('close');
}

function openCustomFieldModal() {
  document_actions.openCustomFieldModal(file_details.value);
}

watchEffect(() => {
  if (file_details.value)
    active_details_tab.value = 'details';

  if (shouldShow.value === true) {
    const app = document.getElementById('app');
    setTimeout(() => {
      app.removeAttribute('inert');
    }, 100);
  }
});

const sync_sharepoint_documents_modal = useModal({
  component: DocumentSyncPopup,
});

function openSyncSharepointDocumentsModal(integration, select_all = false) {
  return () => {
    sync_sharepoint_documents_modal.patchOptions({
      attrs: {
        onClose() {
          sync_sharepoint_documents_modal.close();
        },
        submit: async (payload) => {
          await document_store.sync_sharepoint_documents({ body: payload });
          sync_sharepoint_documents_modal.close();
        },
        item: props.item,
        integration,
        select_all,
      },
    });
    sync_sharepoint_documents_modal.open();
  };
}

const sharepoint_sync_options = integration => [{ label: 'Sync all documents', on_click: openSyncSharepointDocumentsModal(integration, true) }, { label: 'Choose documents to sync', on_click: openSyncSharepointDocumentsModal(integration) }];
</script>

<template>
  <HawkExportToast
    v-if="is_metadata_exporting"
    class="z-[1003]"
    :submit="exportDocumentsMetaData"
    :progress_text="$t('Exporting documents')"
    :completed_text="$t('Documents exported')"
    @cancel="cancelExportingMetadata"
    @close="() => is_metadata_exporting = false"
  />
  <!-- use hawk-slide over -->
  <TransitionRoot as="template" :show="shouldShow">
    <Dialog as="div" class="relative z-[1000]">
      <div class="fixed inset-y-0 right-0 flex pl-10">
        <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
          <DialogPanel class="pointer-events-auto w-[400px]">
            <div v-if="is_loading" class="relative h-full flex flex-col bg-white shadow-xl flex-1 px-4 sm:px-6 pb-6">
              <hawk-loader />
            </div>
            <div v-else class="relative h-full flex flex-col bg-white shadow-xl flex-1 px-4 sm:px-6 pb-6">
              <div>
                <div class="w-full">
                  <div class="flex items-center justify-between min-w-[330px] max-w-[360px] bg-white pt-6">
                    <div class="text-xs">
                      <DocumentBreadcrumbs :active_item="item" :is_file_details="true" />
                    </div>
                    <div class="min-w-4 min-h-4 text-gray-500">
                      <IconHawkX
                        class="cursor-pointer text-base hover:text-gray-600 outline-none"
                        tabindex="0"
                        @click="onSliderClose"
                      />
                    </div>
                  </div>
                </div>
                <div class="flex flex-col mb-6">
                  <h3 class="font-bold mt-1">
                    {{ props.item?.name }}
                  </h3>
                  <span v-if="!isFolder && !props.item.is_placeholder" class="text-xs text-gray-600 pt-1">
                    {{ $prettysize(props.item?.file_size) }}
                  </span>
                </div>
                <div class="flex justify-between mb-4">
                  <div>
                    <HawkPageHeaderTabs
                      :tabs="[
                        {
                          uid: 'details',
                          label: $t('Details'),
                        },
                        {
                          uid: 'activity',
                          label: $t('Activity'),
                        },
                      ]"
                      :active_item="active_details_tab"
                      @tabClick="active_details_tab = $event.uid"
                    />
                  </div>
                  <div class="flex">
                    <div class="border rounded-md flex items-center justify-center w-9 h-9 ml-1 cursor-pointer text-gray-700" @click="itemDownload">
                      <IconHawkDownloadTwo class="h-5 w-5" />
                    </div>
                    <div v-if="!is_internal && document_permissions.checkDocumentPermission({ permission: 'share', item: props.item })" class="border rounded-md flex items-center justify-center w-9 h-9 ml-1 cursor-pointer text-gray-700" @click="openShareModal">
                      <IconHawkShareSeven class="h-5 w-5" />
                    </div>

                    <div class="border rounded-md flex items-center justify-center w-9 h-9 ml-1 cursor-pointer text-gray-700">
                      <DocumentContextMenu
                        :item="item"
                        :has_bordered_trigger="false"
                        :is_file_details="true"
                        :active_tab="active_tab"
                        :options="{
                          dropdown_items_ids: ['rename', 'edit', 'task', 'form', 'move', 'archive', 'delete', 'export'],
                        }"
                        @click.stop=""
                        @archive="(data) => document_bulk_actions.itemArchive(data)"
                        @delete="(data) => document_crud.itemDelete(data)"
                        @share="(data) => document_bulk_actions.itemShare(data)"
                        @rename="(data) => document_actions.itemRename(data)"
                        @create-task="(data) => document_actions.openCreateTask(data)"
                        @create-form="(data) => document_actions.openCreateForm(data)"
                        @edit="(data) => openCustomFieldModal()"
                        @move="(data) => document_bulk_actions.itemMove(data)"
                        @export="startExporting"
                      >
                        <template #trigger>
                          <IconHawkDotsVertical class="mt-1.5 h-5 w-5" />
                        </template>
                      </DocumentContextMenu>
                    </div>
                  </div>
                </div>
              </div>
              <div class="overflow-y-scroll !overflow-x-hidden scrollbar h-full ">
                <div v-if="active_details_tab === 'details'" class="p-2">
                  <div v-if="!isFolder" class="mb-4 relative group cursor-pointer">
                    <div v-if="!props.item?.versions?.length" class="cursor-default">
                      <div class="w-full flex justify-between items-center mb-4 text-xs text-gray-600 py-6">
                        {{ $t('No document available') }}
                        <span
                          class="font-semibold text-blue-700 text-sm cursor-pointer"
                          @click="openVersionModal"
                        >
                          {{ $t('Upload') }}
                        </span>
                      </div>
                    </div>
                    <div v-else-if="current_version_placeholder || props.item.thumbnail_small">
                      <img
                        loading="lazy"
                        class="shadow-lg w-full object-contain rounded-lg max-h-64"
                        :src="current_version_placeholder || props.item.thumbnail_small"
                        :alt="props.item?.name"
                      >
                      <div v-if="(current_version_placeholder || props.item.thumbnail_small) && getSupportedAttachment(props.item)" class="absolute h-full w-full top-0 place-items-center hidden group-hover:grid" @click="$emit('attachmentClick', item)">
                        <div class="h-full w-full bg-black rounded-lg bg-opacity-20" />
                        <div class="text-white absolute">
                          <IconHawkEye class="h-[30px] w-[30px]" />
                        </div>
                      </div>
                    </div>
                    <div v-else class="w-full flex justify-center items-center mb-4">
                      <div class="h-[120px] w-[120px] rounded-3xl bg-gray-50 flex justify-center items-center relative">
                        <HawkFileIcon class="h-[60px] w-[60px] text-gray-600" :file_name="props.item?.file_name || props.item?.name" />
                        <div v-if="getSupportedAttachment(props.item)" class="absolute h-full w-full top-0 place-items-center hidden group-hover:grid" @click="$emit('attachmentClick', item)">
                          <div class="h-full w-full bg-black rounded-lg bg-opacity-20" />
                          <div class="text-white absolute">
                            <IconHawkEye class="h-[30px] w-[30px]" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="w-full flex justify-center items-center mb-4">
                    <div class="h-[120px] w-[120px] rounded-3xl bg-gray-50 flex justify-center items-center">
                      <IconHawkFolder class="h-[60px] w-[60px] text-gray-600" />
                    </div>
                  </div>
                  <div class="flex flex-col mb-4">
                    <h6 class="font-medium text-sm mb-2 text-gray-500">
                      {{ $t('Owner') }}
                    </h6>
                    <div class="flex justify-between">
                      <div class="flex items-center">
                        <HawkMembers :members="props.item?.owner" type="label" size="xs" />
                      </div>
                      <span v-if="!is_internal && document_permissions.checkDocumentPermission({ permission: 'transfer', item: props.item })" class="font-medium font-semibold text-blue-700 text-sm cursor-pointer" @click="openTransferOwnershipModal">{{ $t('Transfer') }}</span>
                    </div>
                  </div>
                  <hr class="my-4">
                  <div class="flex flex-col">
                    <h6 class="font-medium text-sm mb-2 text-gray-500">
                      {{ $t('Uploaded By') }}
                    </h6>
                    <div class="flex items-center">
                      <div class="flex">
                        <HawkMembers :members="props.item?.created_by?.uid" size="xs" class="mr-2" />
                      </div>
                      <div class="flex flex-col">
                        <HawkMembers :members="props.item?.created_by?.uid" type="label" :has_avatar="false" name_classes="!text-sm !font-normal" />
                        <span class="text-xs">
                          {{ $date(props.item.created_at, 'L_DATETIME_MED') }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <hr class="my-4">
                  <div class="flex flex-col">
                    <div class="flex justify-between">
                      <h6 class="font-medium text-sm mb-2 text-gray-500">
                        {{ $t('Shared with') }}
                      </h6>
                      <span v-if="!is_internal && document_permissions.checkDocumentPermission({ permission: 'share', item: props.item })" class="font-medium font-semibold text-blue-700 text-sm cursor-pointer" @click="openShareModal">{{ $t('Edit') }}</span>
                    </div>
                    <div v-if="props.item.members?.length">
                      <HawkMembers
                        :members="props.item.members" :max_badges_to_display="100"
                        type="badge"
                      />
                    </div>
                    <span v-else class="text-sm text-gray-600">-</span>
                  </div>
                  <!-- Custom fields -->
                  <template v-if="auth_store.check_split('files_v2_advanced') && !isFolder ">
                    <hr class="my-4">
                    <div class="flex justify-between mb-2">
                      <h6 class="font-medium text-sm mb-2 text-gray-500">
                        {{ $t('Properties') }}
                      </h6>
                      <span
                        class="font-semibold text-blue-700 text-sm cursor-pointer"
                        @click="openCustomFieldModal"
                      >
                        {{ $t('Edit') }}
                      </span>
                    </div>
                    <div>
                      <h6 class="font-medium text-sm text-gray-500">
                        {{ $t('Number') }}
                      </h6>
                      <div class="flex items-center w-fit rounded-md p-1">
                        <span class="text-sm ">
                          {{ file_details.number ? file_details.number : '-' }}
                        </span>
                      </div>
                    </div>
                    <div class="my-2">
                      <h6 class="font-medium text-sm text-gray-500">
                        {{ $t('Status') }}
                      </h6>
                      <div class="flex items-center w-fit rounded-md p-1">
                        <HawkBadge v-if="!isEmpty(file_details.status)" :custom_color="dms_settings_store.document_status_map[file_details.status?.uid]?.color">
                          {{ file_details.status.name }}
                        </HawkBadge>
                        <span v-else>-</span>
                      </div>
                    </div>
                    <div class="my-2">
                      <h6 class="font-medium text-sm text-gray-500">
                        {{ $t('Category') }}
                      </h6>
                      <div class="flex items-center w-fit rounded-md p-1">
                        <hawk-category-name :uid="file_details.category" />
                      </div>
                    </div>
                    <div class="my-2">
                      <h6 class="font-medium text-sm text-gray-500">
                        {{ $t('Tags') }}
                      </h6>
                      <div class="flex items-center w-fit rounded-md p-1">
                        <div v-if="file_details.tags?.length">
                          <hawk-tags-name :tags="file_details.tags" />
                        </div>
                        <div v-else>
                          -
                        </div>
                      </div>
                    </div>
                    <div v-if="custom_fields?.length">
                      <div v-for="custom_field in custom_fields" :key="custom_field.uid" class="my-2">
                        <h6 class="font-medium text-sm text-gray-500">
                          {{ custom_field.label }}
                        </h6>
                        <div class="flex items-center w-fit rounded-md p-1">
                          <hawk-custom-field class="text-sm" :field="custom_field" :value="field_values_map?.[custom_field.uid]?.value" />
                        </div>
                      </div>
                    </div>
                  </template>
                  <hr v-if="!isFolder" class="my-4">
                  <div v-if="!isFolder">
                    <div class="flex justify-between">
                      <h6 class="font-medium text-sm mb-2 text-gray-500">
                        {{ $t('Versions') }}
                      </h6>
                      <span v-if="document_permissions.checkDocumentPermission({ permission: 'versions', item: props.item })" class="font-medium font-semibold text-blue-700 text-sm cursor-pointer" @click="openVersionModal">{{ $t('New Version') }}</span>
                    </div>
                    <div v-if="file_details?.versions?.length === 0" class="text-center p-10 text-gray-400 text-sm">
                      No version added for the document
                    </div>
                    <DocumentVersions :versions="file_details?.versions" :file="file_details" />
                  </div>
                  <template v-if="auth_store.check_split('files_v2_advanced') && !isFolder && file_details?.all_transmittals?.length">
                    <hr class="my-4">
                    <h6 class="font-medium text-sm mb-2 text-gray-500">
                      {{ $t('Transmittals') }}
                    </h6>
                    <div v-for="transmittal in file_details.all_transmittals" :key="transmittal.uid" class="mb-5">
                      <div class="flex gap-2 items-center">
                        <HawkText class="text-sm font-medium text-gray-900 cursor-pointer" :length="25" :content="transmittal.number" @click="router.push({ name: 'files-transmittals-detail', params: { asset_id: file_details.asset, transmittal_uid: transmittal.uid } })" />
                        <span v-if="transmittal?.issue_purpose" class="text-xs text-gray-600">({{ transmittal?.issue_purpose }})</span>
                      </div>
                      <div class="flex flex-wrap gap-2 items-center my-1">
                        <HawkBadge v-if="file_details.active_transmittal.uid === transmittal.uid" color="green" class="whitespace-nowrap">
                          {{ $t('Active') }}
                        </HawkBadge>
                        <HawkBadge v-if="transmittal?.review_status?.name" :color="TRANSMITTAL_STATUS[transmittal.review_status.name].color" class="whitespace-nowrap" :custom_classes="transmittal_status_class_map[TRANSMITTAL_STATUS[transmittal.review_status.name].color]">
                          {{ TRANSMITTAL_STATUS[transmittal.review_status.name].title || transmittal.review_status.label }}
                        </HawkBadge>
                        <span class="text-xs text-gray-600 text-nowrap">{{ $date(transmittal.timestamp, 'L_DATETIME_MED') }}</span>
                      </div>
                    </div>
                  </template>
                  <template v-if="auth_store.check_split('files_v2_advanced') && isFolder && integrations?.length">
                    <hr class="my-4">
                    <div class="flex justify-between items-center">
                      <h6 class="font-medium text-sm mb-2 text-gray-500">
                        {{ $t('Integrations') }}
                      </h6>
                      <hawk-button type="link" @click="document_bulk_actions.itemIntegrate({ item: file_details })">
                        <IconHawkPlus />  {{ $t('Integrate') }}
                      </hawk-button>
                    </div>
                    <div v-for="integration in integrations" :key="integration.id" class="mb-5 group">
                      <div v-if="integration.type === 'Sharepoint' && integration.active">
                        <div class="border rounded-lg p-2 bg-gray-25 mt-2">
                          <div class="flex items-center justify-between ">
                            <div class="flex items-center text-sm font-semibold text-gray-600 ">
                              <IconHawkSharepoint class="w-5 h-5 mr-2" />
                              {{ $t('Sharepoint') }}
                            </div>
                            <div class="flex items-center">
                              <hawk-button v-if="integration?.is_syncing" type="plain">
                                <icon-hawk-refresh-ccw-five class="opacity-60	 animate-spin" />
                              </hawk-button>
                              <hawk-button v-else-if="!integration?.can_sync" type="plain" class="group-hover:visible invisible">
                                <icon-hawk-refresh-ccw-five class="opacity-30" />
                              </hawk-button>
                              <hawk-menu v-else :items="sharepoint_sync_options(integration)" class="group-hover:visible invisible">
                                <template #trigger>
                                  <hawk-button type="plain">
                                    <icon-hawk-refresh-ccw-five />
                                  </hawk-button>
                                </template>
                              </hawk-menu>
                              <hawk-button type="plain" class="group-hover:visible invisible" @click="openRemoveIntegrationModal(integration)">
                                <icon-hawk-trash-three />
                              </hawk-button>
                            </div>
                          </div>
                          <div class="border-t my-2 pt-2">
                            <div class="grid grid-cols-[100px,1fr] text-xs font-regular text-gray-600 gap-2">
                              <div class="pt-0.5">
                                {{ $t('Account') }}:
                              </div>
                              <div class="text-sm">
                                {{ integration.metadata?.account_name || '-' }}
                              </div>
                              <div class="pt-0.5">
                                {{ $t('Site') }}:
                              </div>
                              <div class="text-sm">
                                {{ integration.metadata?.site_name || '-' }}
                              </div>
                              <div class="pt-0.5">
                                {{ $t('Location') }}:
                              </div>
                              <div class="text-sm">
                                {{ integration.metadata?.destination_name || '-' }}
                              </div>
                              <div class="pt-0.5">
                                {{ $t('Integrated by') }}:
                              </div>
                              <div class="text-sm">
                                <hawk-members :members="integration.owner" :has_name="true" type="badge" />
                                <div> {{ $date(integration.integrated_at, 'L_DATETIME_MED') }} </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>

                  <DocumentTasks
                    v-if="file_details?.uid"
                    :document="file_details"
                    :store_key="props.options?.module"
                  />
                  <DocumentForms
                    v-if="file_details?.uid"
                    :document="file_details"
                    :store_key="props.options?.module"
                  />
                </div>
                <div v-show="active_details_tab === 'activity'">
                  <HawkActivities
                    ref="hawk_activities"
                    module="dms"
                    :can_initialize="false" :resource_uid="file_details?.uid" :resource_type="file_details.type" :is_timeline="true"
                  />
                </div>
              </div>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
