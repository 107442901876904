<script setup>
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useThermStore } from '~/therm/store/therm.store';
import ThermReportGraph from '~/therm/components/therm-report-graph.vue';

const { $t } = useCommonImports();
const therm_store = useThermStore('therm_dashboard');

const severity_levels_graph_details = computed(() => {
  return {
    type: 'pie2d',
    title: $t('Defects by severity'),
    id: 'severity_levels_container',
  };
});
const severity_levels_graph_data = computed(() => {
  const severity = {
    'Severity 1': { value: 0, color: '#1570EF' },
    'Severity 2': { value: 0, color: '#53B1FD' },
    'Severity 3': { value: 0, color: '#1849A9' },
  };
  therm_store.report_defects.forEach((feature) => {
    const diff = feature.temperature_difference;
    if (diff && diff > 40)
      severity['Severity 3'].value++;

    else if (
      [108, 109, 303, 315].includes(feature.type_id)
    )
      severity['Severity 1'].value++;

    else
      severity['Severity 2'].value++;
  });
  const data = [];
  for (const severity_level in severity)
    data.push({
      label: severity_level,
      ...severity[severity_level],
    });
  return { data, description: true, type: 'severity' };
});
</script>

<template>
  <div class="border rounded-xl h-[260px] p-4">
    <ThermReportGraph
      :data="severity_levels_graph_data"
      :graph_details="severity_levels_graph_details"
      :has_borders="false"
      height="200"
    />
  </div>
</template>
