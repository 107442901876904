<script setup>
import dayjs from 'dayjs';
import { useModal } from 'vue-final-modal';
import { useRoute, useRouter } from 'vue-router';
import { computed } from 'vue';
import { useTransmittalsStore } from '~/dms/store/transmittals.store';
import { useCommonStore } from '~/common/stores/common.store';
import { useTransmittalActions } from '~/dms/composables/transmittal-actions.composable.js';

// Modal
import DocumentBrowsePopup from '~/dms/components/atoms/document-browse-popup.vue';
import TransmittalReviewStatus from '~/dms/components/transmittals/transmittal-review-status.vue';

const props = defineProps({
  form$: {
    required: true,
  },
  transmittal: { type: Object },
  classes: {
    type: String,
    required: false,
    default: () => 'col-span-12 -mx-4 px-4',
  },
  is_public_page: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Object,
    default: () => {
      return {
        is_date_disabled: false,
        can_add_documents: true,
      };
    },
  },
  read_only: {
    type: Boolean,
    default: false,
  },
  visible_columns: {
    type: Array,
    default: () => {
      return [
        'name',
        'versions',
        'due_date',
        'comments_out',
        'delete',
      ];
    },
  },
});
const emits = defineEmits(['delete', 'closeAttachment', 'rowClick']);
const $t = inject('$t');

const router = useRouter();
const route = useRoute();

const transmittal_store = useTransmittalsStore();
const common_store = useCommonStore();

const state = reactive({
  selected_documents: [],
  table_instance: null,
  bulk_options: [],
});

const { dropdown_items, createPayload, clearBulkSelection } = useTransmittalActions(state);

const width_divider = props.read_only ? 20 : 8;

const columns = ref([{
  accessorKey: 'select',
  header: '',
  id: 'select',
  size: 3,
  enableSorting: false,
  enableResizing: false,
  show_on_hover: true,
},
{
  header: $t('Documents'),
  accessorKey: 'name',
  id: 'name',
  size: window.innerWidth / width_divider,
  enableResizing: false,
},
{
  header: $t('Revisions'),
  accessorKey: 'versions',
  id: 'versions',
  size: 3,
  enableResizing: false,
},
{
  header: props?.options?.due_date_header || $t('Due date'),
  accessorKey: 'due_date',
  id: 'due_date',
  size: 138,
  enableResizing: false,
},
{
  header: $t('Comments in'),
  accessorKey: 'comments_in',
  id: 'comments_in',
  size: window.innerWidth / width_divider + 60,
  enableResizing: false,
},
{
  header: $t('Comments out'),
  accessorKey: 'comments_out',
  id: 'comments_out',
  size: window.innerWidth / width_divider + 60,
  enableResizing: false,
},
{
  header: $t('Status'),
  accessorKey: 'status',
  id: 'status',
  size: window.innerWidth / width_divider,
  enableResizing: false,
},
{
  header: '',
  accessorKey: 'locked',
  id: 'locked',
  size: '3',
  enableResizing: false,
},
{
  header: '',
  accessorKey: 'delete',
  id: 'delete',
  size: '3',
  enableResizing: false,
},
{
  header: '',
  accessorKey: 'public_uploads',
  id: 'public_uploads',
  size: '3',
  enableResizing: false,
},
{
  accessorKey: 'context_menu',
  header: '',
  id: 'context_menu',
  size: '3',
  show_on_hover: 'true',
},
]);

const form$ = computed(() => props.form$);
const table_data = computed(() => Object.values(props.transmittal?.documents || []).filter(doc => doc.uid));
const filtered_columns = computed(() => columns.value.filter(column => props.visible_columns.includes(column.id)));
const transmittal = computed(() => transmittal_store.transmittal);
const document_status_map = computed(() => transmittal.value?.review_status_set);

// document's modal
const browse_popup = useModal({
  component: DocumentBrowsePopup,
});

function openBrowsePopup() {
  browse_popup.patchOptions({
    attrs: {
      title: $t('Select documents from Files'),
      is_transmittal: true,
      action_label: $t('Upload Documents'),
      on_submit: ({ selected_documents = [] }) => {
        addDocuments({ selected_documents, callback: browse_popup.close });
      },
      onClose() {
        browse_popup.close();
      },
    },
  });
  browse_popup.open();
}

function addDocuments({ selected_documents, callback }) {
  selected_documents.forEach((document) => {
    const { uid, name, thumbnail_large, thumbnail_small, thumbnail_xsmall, service, active_transmittal } = document;
    if (!form$.value.data.documents[document.uid])
      form$.value.data.documents[document.uid] = {
        uid,
        name,
        thumbnail_large,
        thumbnail_small,
        thumbnail_xsmall,
        due_date: new Date(),
        service_object: service,
        active_transmittal,
      };
  });
  if (props?.form$?.elements$?.documents)
    props.form$.elements$.documents.validate();
  callback();
}

function commonOptionsForDocument() {
  const common_keys = {};
  for (const obj of state.selected_documents)
    for (const key in obj.actions)
      if (obj.actions[key] === true)
        if (common_keys[key])
          common_keys[key]++;
        else
          common_keys[key] = 1;

  const common_options = Object.keys(common_keys).filter(key => common_keys[key] === state.selected_documents.length);
  return dropdown_items(null, common_options);
}

function handleRowSelection(e) {
  state.selected_documents = e.map(forms_list_row => forms_list_row.original);
  state.bulk_options = commonOptionsForDocument(); // find common options
}

function formatTable(cell) {
  if (cell?.row?.original?.locked)
    return { backgroundColor: '#F2F4F7' };
  return {};
}

function checkPermission(document, type) {
  if (transmittal.value?.uid)
    return document?.actions?.[type] || false;

  return true;
}
</script>

<template>
  <div :class="classes">
    <div class="mb-2 text-sm font-semibold">
      {{ $t('Documents') }} <span v-if="table_data.length"> ({{ table_data.length }})</span>
    </div>
    <TransmittalDetailDocumentBulkActions
      v-if="state.bulk_options?.length > 0"
      :selected_items="state.selected_documents"
      :bulk_action_options="state.bulk_options"
      class="mb-4"
      @clear="clearBulkSelection"
    />
    <hawk-table
      :columns="filtered_columns"
      :is_vueform_element_enabled="true"
      :data="table_data"
      class="!px-0"
      :is_gapless="true"
      :show_menu_header="false"
      :disable_resize="true"
      :pagination_config=" { totalRows: table_data?.length, pageSize: 25 }"
      scroll_to_top_on_pagination
      :format-table="formatTable"
      @tableInstance="state.table_instance = $event"
      @select-row="handleRowSelection"
      @row-clicked="emits('rowClick', $event)"
    >
      <template #due_dateHeader>
        {{ options?.due_date_header || $t('Due date') }}
      </template>
      <template #name="slotProps">
        <div class="flex items-center gap-4">
          <div>
            <div class="text-xs text-gray-600 font-regular">
              {{ slotProps.data.row.original.number }}
            </div>
            <div class="text-sm font-medium text-gray-800">
              {{ slotProps.data.row.original.name }}
              <hawk-badge v-if="slotProps.data.row.original.unread" color="blue" type="light">
                {{ $t('New') }}
              </hawk-badge>
            </div>
          </div>
          <div
            v-if="slotProps.data.row.original?.waiting_on?.number"
            class="flex items-center bg-warning-50 rounded-full py-1 px-2 text-warning-600 gap-2 hover:bg-warning-100 !pointer-events-auto cursor-pointer"
            @click="router.push({ name: 'files-transmittals-detail', params: { asset_id: route.params.asset_id, transmittal_uid: slotProps.data.row.original?.waiting_on?.uid } })"
          >
            <IconHawkSlashCircleOne v-tippy="`Waiting on transmittal &quot;${slotProps.data.row.original?.waiting_on?.number}&quot; `" class="w-3 h-3" />
            <IconHawkChevronRight class="w-4 h-4" />
          </div>
        </div>
      </template>
      <template #due_date="{ data: { row: { original: { due_date } } } }">
        <div
          v-if="options.is_date_disabled"
          :class="[due_date && dayjs(due_date) <= dayjs() ? 'text-red-700' : 'text-gray-900']"
        >
          {{ options.due_date ? options.due_date : due_date ? $date(due_date, 'DATE_MED') : '-' }}
        </div>
        <DateTimeElement
          v-else
          :default="due_date"
          :options="{
            'min-date': dayjs().format('YYYY-MM-DD'),
          }"
          load-format="YYYYYY-MM-DDTHH:mm:ss.sssZ"
          :rules="['required']"
          name="due_date"
        />
      </template>
      <template #versions="{ data: { row: { original } } }">
        {{ original.versions ? original.versions.length : original.service_object ? 1 : 0 }}
      </template>
      <template #comments_in="{ data: { row: { original: { comments_in } } } }">
        <div class="text-gray-900 break-all">
          {{ comments_in || '-' }}
        </div>
      </template>
      <template #comments_out="{ data: { row: { original } } }">
        <TextareaElement
          v-if="options.is_comments_enabled || is_public_page || checkPermission(original, 'can_review') || checkPermission(original, 'can_submit')"
          class="w-full"
          :default="original?.draft?.comments || original?.comments_out"
          name="comments"
          rows="1"
          placeholder="Comment here"
          @click.stop
        />
        <div v-else class="text-gray-700 text-sm break-all">
          {{ original?.draft?.comments || original?.comments || original?.comments_out || '-' }}
        </div>
      </template>
      <template #status="{ data: { row: { original } } }">
        <div v-if="is_public_page">
          <hawk-badge v-if="form$.data.documents[original.uid]?.file" color="green">
            {{ $t('Uploaded') }}
          </hawk-badge>
          <hawk-badge v-else color="yellow">
            {{ $t('Waiting for submission') }}
          </hawk-badge>
        </div>
        <div v-else>
          <TransmittalReviewStatus :document="original" :document_status_map="document_status_map" />
        </div>
      </template>
      <template #locked="{ data: { row: { original } } }">
        <div v-if="original.locked" class="pointer-events-auto">
          <IconHawkLockOne class="text-gray-500" />
        </div>
        <span v-else />
      </template>
      <template #delete="delete_row">
        <hawk-button type="text" @click="emits('delete', delete_row.data.row.original.uid)">
          <IconHawkTrashOne />
        </hawk-button>
      </template>
      <template #public_uploads="{ data: { row: { original } } }">
        <FileElement
          name="file"
          class="w-full -mt-2"
          :presets="['hawk_file_element']"
          :can-select="true"
        >
          <template #upload-trigger>
            Upload
          </template>
        </FileElement>
      </template>
      <template #context_menu="{ data: { row: { original } } }">
        <hawk-menu v-if="dropdown_items(original).length > 0" :items="dropdown_items(original)" additional_dropdown_classes="w-[200px]" position="bottom-left" @click.stop>
          <template #trigger>
            <IconHawkDotsVertical class="h-4 w-4" />
          </template>
        </hawk-menu>
      </template>
    </hawk-table>
    <hawk-button v-if="options.can_add_documents" type="link" @click="openBrowsePopup">
      <icon-hawk-plus />
      {{ $t('Add documents') }}
    </hawk-button>
  </div>
</template>
